import { createRouter, createWebHistory } from "vue-router";

const login = () => import("@/views/auth/login.vue");

import main from "@/Main.vue";
import auth from "@/Auth.vue";
import home from "@/views/home.vue";

import err404 from "@/views/err404.vue";
import { useStore } from "@/store";

const routes = [
  {
    path: "/auth",
    component: auth,
    meta: { guest: true },
    children: [
      {
        path: "login",
        name: "login",
        component: login,
        meta: { title: "Авторизация" },
      },
    ],
  },
  {
    path: "/",
    component: main,
    meta: { auth: true },
    children: [
      {
        path: "",
        name: "home",
        component: home,
        meta: { title: "Дашборд", employer: false },
      },
      {
        path: "clients",
        name: "clients",
        component: () => import("@/views/clients/index.vue"),
        meta: { title: "Клиенты" },
      },
      {
        path: "questions",
        name: "questions",
        component: () => import("@/views/questions/index.vue"),
        meta: { title: "Обращения" },
      },
      {
        path: "questions/:id",
        name: "question",
        component: () => import("@/views/questions/detail.vue"),
        meta: { title: "Обращения" },
      },
      {
        path: "reports",
        name: "reports",
        component: () => import("@/views/reports/index.vue"),
        meta: { title: "Аналитика" },
      },
      {
        path: "partners",
        name: "partners",
        component: () => import("@/views/partners/index.vue"),
        meta: { title: "Аналитика" },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: err404,
        meta: { title: "404" },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: err404,
    meta: { title: "404" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const store = useStore();
  if (store.token && !store.auth) {
    await store.loadData();
  }

  if (to.meta.auth && !store.auth) {
    next({ name: "login", query: { redirect: to.fullPath } });
  } else if (to.meta.guest && store.auth) {
    next({ name: "home" });
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
